@import './variables';

.primary-button {
    border: none;
    background-color: $primary-button-default;
    border-radius: 5px;
    color: $white;
    font-family: $font-family-regular;
    font-size: 16px;
    height: 44px;
    min-width: 200px;
    padding: 1px 10px;
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
      color: $white;
    }
    &:focus {
      box-shadow: 0 0 0 2px rgba(77, 180, 250, 0.5);
      outline: none;
    }
    &:hover {
      background-color: $primary-button-hover;
      color: $white;
    }
    &:active {
      background-color: $primary-button-hover;
      color: $white;
    }
    &.button-dismissive {
      min-width: 155px;
      background-color: $white;
      color: $primary-color;
      font-family: $font-family-regular;
      border: solid 2px $primary-color;
      &:hover {
        background-color: $white;
        border: solid 2px #164469;
      }
    }
    &.button-round {
      border-radius: 100px;
      font-size: 16px;
      height: 35px;
      min-width: 100px;
      &.button-round-long {
        min-width: 150px;
      }
    }
    &.button-small {
      line-height: 0px;
      height: 35px;
      min-width: 105px;
    }
  }

  .icon-button {
    //default button
    min-width: 105px;
    height: 35px;
    border: none;
    margin: auto;
    padding: 0px;
    background-color: $white;
    font-family: $font-family-regular;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    outline: none;
    outline: 0 !important;
    letter-spacing: normal;
    text-align: center;
    color: $primary-button-default;
    &:hover {
      outline: none;
      outline: 0 !important;
    }
    &:active {
      outline: none;
      outline: 0 !important;
    }
    &:focus {
      color: $primary-button-hover;
      border: none;
      outline: none;
      outline: 0 !important;
    }
  
    &.gray-button {
      background-color: $off-white;
    }
    &.icon-button-small {
      min-width: 1rem;
      height: 1rem;
    }
  }

  .img-icon-button {
    vertical-align: 0rem !important;
  }
  
  // button css
.card-button-row {
  button {
    margin-top: 15px;
    margin-right: 25px;
    &:last-child {
      margin-right: 0px;
    }
  }
}
  
@import '../../../../../_styles/variables';

.option-select-dialog-container .mat-dialog-container {
    padding: 38px 100px 43px 100px;
}

.tree-select-modal-container .mat-dialog-container {
  padding: 20px 50px 40px 50px;
}

.delete-user-modal-container .mat-dialog-container {
    padding: 20px 40px 40px 40px;
  }

#osmb_close {
    margin-right: -24px; // Move close icon slightly to the right to break out of modal container
}

.icon-container {
    justify-content: center;
    display: flex;
}

.modal-title {
    text-align: left;
    font-family: $font-family-regular;
    font-weight: $font-weight-bold;
    font-size: 23px;
    line-height: 1.39;
    color: #2a2a2a;
    margin: 12px auto 20px auto;
}

.option-select-button-container {
    display: flex;
}

.btn {
    min-width: 155px;
    min-height: 44px;
    font-family: $font-family-regular;
    font-weight: $font-weight-bold;
    line-height: 1.5;
}

.close-container {
    float: right;
}

.btn-small{
    min-width: 90px;
    min-height: 44px;
}

@import './variables';

$disable-background-color: #eceeef;
.multiselect-dropdown {
	position: relative;
	width: 100%;
	font-size: 1em !important;
	font-family: $font-family-regular !important;

	.dropdown-btn {
		display: inline-flex !important;
		border: 1px solid #adadad;
		width: 100%;
		padding: 6px 12px;
		margin-bottom: 0;
		font-weight: normal;
		line-height: 1.5;
		text-align: left;
		vertical-align: middle;
		cursor: pointer;
		background-image: none;
		border-radius: 4px;

		align-items: center !important;
		height: 44px;
		color: $dark-grey !important;
		font-size: 1rem;
		background-color: #ffffff !important;
		background-clip: padding-box !important;
		border: 2px solid #e2e3e4 !important;

		.selected-item {
			border: 1px solid $primary-color !important;
			margin-right: 4px;
			background: $primary-color !important;
			padding: 0px 5px;
			color: #fff;
			border-radius: 2px;
			float: left;
			a {
				text-decoration: none;
				padding-left: 5px !important;
			}
		}
		.selected-item:hover {
			box-shadow: unset !important;
		}
		.dropdown-down {
			display: inline-block;
			top: 10px;
			width: 0;
			height: 0;
			border-top: 10px solid #adadad;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
		.dropdown-up {
			display: inline-block;
			width: 0;
			height: 0;
			border-bottom: 10px solid #adadad;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
		}
		.dropdown-multiselect__caret {
			width: unset !important;
			height: unset !important;
			transition: unset !important;
			padding: 9px 8px !important;
		}

		.dropdown-multiselect__caret:before {
			position: relative;
			right: 0;
			top: unset !important;
			color: #999;
			margin-top: 4px;
			border-style: unset !important;
			border-width: unset !important;
			border-color: unset !important;
			content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%23848584' d='M7.41 8.958L12 13.599 16.59 8.958 18 10.387 12 16.467 6 10.387z' transform='translate(-425 -378) translate(425 378)' /%3e%3c/svg%3e") !important;
		}

		.dropdown-multiselect--active .dropdown-multiselect__caret {
			transform: rotateZ(180deg) !important;
		}
	}
	.disabled {
		& > span {
			background-color: $disable-background-color;
		}
	}
}

.dropdown-list {
	position: absolute;
	padding-top: unset !important;
	width: 100%;
	z-index: 9999;
	border: 2px solid #e2e3e4 !important;
	border-radius: 3px;
	background: #fff;
	margin-top: 6px !important;
	box-shadow: unset !important;

	ul {
		padding: 0px;
		list-style: none;
		overflow: auto;
		margin: 0px;
	}
	li {
		height: 46px !important;
		padding: 10px 13px !important;
		cursor: pointer;
		text-align: left;
		border-bottom: unset !important;
	}

	.filter-textbox {
		border-bottom: 1px solid #ccc;
		position: relative;
		padding: 10px;
		input {
			border: 0px;
			width: 100%;
			padding: 0px 0px 0px 26px;
		}
		input:focus {
			outline: none;
		}
	}
}

.multiselect-item-checkbox:hover {
	background-color: #f5f6f6 !important;
}
.multiselect-item-checkbox input[type='checkbox'] {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.multiselect-item-checkbox input[type='checkbox']:focus + div:before,
.multiselect-item-checkbox input[type='checkbox']:hover + div:before {
	border-color: $primary-color !important;
}

.multiselect-item-checkbox input[type='checkbox']:active + div:before {
	transition-duration: 0s;
}

.multiselect-item-checkbox input[type='checkbox'] + div {
	position: relative;
	padding-left: 2.5em !important;
	vertical-align: middle;
	user-select: none;
	cursor: pointer;
	margin: 0px;
	color: $dark-grey !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:hover {
	color: $primary-color !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:before {
	box-sizing: content-box;
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	width: 14px;
	height: 14px;
	margin-top: -9px;
	border: 2px solid #c8c9c7 !important;
	border-radius: 0.125em !important;
	text-align: center;
	transition: all 0.4s ease;
	background-color: #ffffff;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
	box-sizing: content-box;
	content: '';
	background-color: $primary-color !important;
	position: absolute;
	top: 50%;
	left: 4px;
	width: 10px;
	height: 10px;
	margin-top: -5px;
	transform: scale(0);
	transform-origin: 50%;
	transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:disabled + div:before {
	border-color: #cccccc;
}

.multiselect-item-checkbox
	input[type='checkbox']:disabled:focus
	+ div:before
	.multiselect-item-checkbox
	input[type='checkbox']:disabled:hover
	+ div:before {
	background-color: inherit;
}

.multiselect-item-checkbox input[type='checkbox']:disabled:checked + div:before {
	background-color: #f5f6f6 !important;
}

.multiselect-item-checkbox input[type='checkbox'] + div:after {
	background-color: transparent;
	top: 50%;
	left: 4px;
	width: 8px;
	height: 3px;
	margin-top: -4px;
	border-style: solid;
	border-color: #ffffff;
	border-width: 0 0 3px 3px;
	border-image: none;
	transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
	content: '';
	transform: rotate(-45deg) scale(1);
	transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:before {
	animation: borderscale 200ms ease-in;
	background: $primary-color !important;
	border-color: $primary-color !important;
}

.multiselect-item-checkbox input[type='checkbox']:checked + div:after {
	transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
	50% {
		box-shadow: 0 0 0 2px $primary-color !important;
	}
}

@import "variables";

.mat-step-header .mat-step-icon {
  background-color: $white;
  border: 3px solid #c8c8c8;
  color: $white;
}
.mat-step-header .mat-step-icon-state-done {
  background-color: $primary-color;
  border: 3px solid $primary-color;
  color: $white;
}
.mat-step-header .mat-step-icon-selected {
  background-color: $white;
  border: 3px solid $primary-color;
  color: $white;
}
.mat-vertical-stepper-header .mat-step-icon {
  height: 20px;
  width: 20px;
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-label.mat-step-label-active {
  border: solid 1px #e9e9e9;
  background-color: #f6f6f6;
  box-sizing: content-box;
  width: 100%;
  height: 40px;
  font-size: 24px;
  padding: 6px 5px 0px 20px;
  font-family: $font-family-base;
  line-height: 1.25;
  color: #626262;
  font-weight: 300;
  &.mat-step-label-selected {
    color: $black;
    font-weight: normal;
    font-family: $font-family-regular;
  }
}

.mat-step-header.cdk-keyboard-focused,
.mat-step-header.cdk-program-focused,
.mat-step-header:hover {
  background-color: white;
}
.mat-step-header .mat-step-header-ripple {
  position: static !important;
}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 18px 24px;
}
.mat-vertical-content {
  padding: 0 24px 0px 24px !important;
}
.header-enrollment {
  font-family: $font-family-regular;
  font-size: 28px;
  line-height: 1.14;
  color: $black;
}
.header-padding {
  padding: 60px 60px 10px 60px;
}
.header-info {
  font-family: $font-family-regular;
  font-size: 16px;
  color: $dark-grey;
}

// *Employee enrollment CSS changes for accordion
.header-enrollment {
  font-family: $font-family-regular;
  font-size: 28px;
  line-height: 1.14;
  color: $black;
}
.header-padding {
  padding: 60px 60px 10px 60px;
}
.header-info {
  font-family: $font-family-regular;
  font-size: 16px;
  color: $dark-grey;
}
.info-padding {
  padding-top: 20px;
}

.edit-icon {
  pointer-events: all;
}
.mat-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 20px;
  width: 20px;
}
hr {
  margin-top: 0px;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-left: 60px;
  margin-right: 24px;
}
.header-styling {
  font-family: $font-family-base;
  color: $black;
  font-weight: 500;
}
.button-padding {
  margin-left: 60px;
}
::ng-deep .mat-vertical-stepper-content[aria-expanded="true"] {
  overflow: visible !important;
}

$srcPath: "../../assets/fonts/";

// Roboto fonts
@font-face {
  font-family: Roboto-Light;
  src: url($srcPath + 'roboto-light.woff') format('woff'), url($srcPath + 'roboto-light.woff2') format('woff2'), url($srcPath + 'roboto-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto-regular;
  src: url($srcPath + 'roboto-regular.woff') format('woff'), url($srcPath + 'roboto-regular.woff2') format('woff2'), url($srcPath + 'roboto-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import "variables";

.dropdown {
  border: none;
  background: transparent;
}

.dropdown-toggle {
  border: none;
  background: transparent;
}

.dropdown-toggle:not(.dropdown-no-caret):not(ngb-datepicker)::after {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%23848584' d='M7.41 8.958L12 13.599 16.59 8.958 18 10.387 12 16.467 6 10.387z' transform='translate(-425 -378) translate(425 378)' /%3e%3c/svg%3e");
}

.dropdown-toggle.dropdown-caret-float-right::after {
  float: right;
}

.dropdown-menu {
  padding: 22px 0;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  border: 2px solid $light-gray;
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #e6e6e6;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.04);
  }

  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.04);
    background: #b4b4b4;
    border-radius: 4px;
  }

  li > a {
    padding: 0 40px;
    word-wrap: break-word;
    width: 100%; /* change accordingly */
    white-space: normal;
  }
}

.dropup .dropdown-menu {
  top: auto !important;
  bottom: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.dropdown-menu-right {
  margin-right: -40px;
}

.dropdown-item {
  padding: 0px 42px 0px 20px;
  line-height: 44px;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: $blue;
  text-decoration: none;
  background-color: #f5f6f6;
}

/*Dropdown "nipple" on the left side*/
.dropdown-menu:not(.dropdown-menu-right):not(ngb-datepicker):not(.no-nipple):before {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 24px;
  display: block;
  width: 58px;
  height: 44px;
  content: '';
  background: $white;
  box-shadow: none;
}

.dropdown-menu:not(.dropdown-menu-right):not(ngb-datepicker):not(.no-nipple):after {
  position: absolute;
  z-index: -4;
  top: -18px;
  left: 34px;
  display: block;
  width: 16px;
  height: 16px;
  content: '';
  transform: rotate(-45deg) translateY(1rem);
  border-radius: 0.2rem;
  background: $white;
  box-shadow: none;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.08);
}

/*Dropdown "nipple" on the right side*/
.dropdown-menu.dropdown-menu-right:before {
  position: absolute;
  z-index: -2;
  top: 0;
  right: 16px;
  display: block;
  width: 58px;
  height: 44px;
  content: "";
  background: $white;
  box-shadow: none;
}

.dropdown-menu.dropdown-menu-right:after {
  position: absolute;
  z-index: -4;
  top: -18px;
  right: 50px;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
  transform: rotate(-45deg) translateY(1rem);
  border-radius: 0.2rem;
  background: $white;
  box-shadow: none;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: 0 0 20px 8px rgba(0, 0, 0, 0.08);
}

/*Input select dropdown control*/
.dropdown-select {
  height: 44px;
  color: #000 !important;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 0.25rem;
  background-color: $white !important;
  background-clip: padding-box !important;
  border: 2px solid $light-gray !important;

  .dropdown-toggle {
    padding: 10px 23px 10px 18px;
    color: $dark-grey !important;
    display: block;
    text-decoration: none !important;
    overflow: hidden;
    width: 85%;
  }

  .dropdown-toggle::after {
    font-size: 10px !important;
    position: absolute !important;
    top: 10px;
    right: 10px;
    border: 0;
  }

  .dropdown-menu {
    padding: 7px 0;
    margin-top: 10px;
    width: 100%;
    min-width: 100%;
    max-height: 215px;
    overflow-y: auto;
  }

  .dropdown-item {
    color: #000;
    padding: 12px 18px 8px 18px !important;
    line-height: 16px;
    line-height: 1.5;
  }

  .dropdown-item:focus
  {
    color: $blue;
    background-color: #f5f6f6;
  }

  .selected > .dropdown-item::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 12'%3e%3cpath fill='%232273BA' fill-rule='evenodd' stroke='%23FFF' d='M2.5 5.5L1 7l4 4 9-8.5L12.5 1 5 8z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    content: "";
    padding-left: 24px;
  }
}

.dropdown-select.open {
  border: solid 2px $primary-color !important;
}

.dropdown-select.open.show {
  .dropdown-toggle::after {
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%23848584' d='M7.41 7.958L12 12.599 16.59 7.958 18 9.387 12 15.467 6 9.387z' transform='translate(-460 -377) rotate(180 242 200.5)' /%3e%3c/svg%3e");
    top: 9px;
  }
}

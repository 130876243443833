@import "fonts";
// Variables
/*********************Base Palette*********************/

//
// Break Points
//
$landscape-break-point: 991px;
// Style Guide Colors
$dark-blue: #164469;
$primary-color: #2273ba;
$light-white: #f5f6f6;
//
// Color system
//
$white: #FFFFFF;
$grey-100: #f8f9fa;
$grey-200: #e9ecef;
$grey-300: #dee2e6;
$grey-400: #ced4da;
$grey-442: #c8c9c7;
$grey-500: #adb5bd;
$grey-600: #6c757d;
$grey-650:#4e4e4e;
$grey-700: #495057;
$grey-800: #343a40;
$grey-900: #212529;
$black: #000;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ff0000;
$red-notification: #f44336;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$silver: rgba(177, 194, 197, 0.2);

// Style Guide Colors
$white: #FFFFFF;
$off-white: #f8f8f8;
$pale-blue: #e8f1f8;
$pale-blue-light: #f3f8fb;
$pale-gray: #f0f0f0;
$light-gray: #e2e3e4;
$light-grey: $light-gray;
$medium-grey: #929292;
$medium-gray: $medium-grey;
$medium-dark-grey: #979797;
$medium-dark-gray: $medium-dark-grey;
$dark-grey: #555555;
$dark-gray: $dark-grey;
$warning: #ffc43d;
$success: #32b89a;
$error: #ff0000;
$orange: #fe6c00;
$blue: #2273ba;
$gradient-start-blue: #3793cf;
$light-blue: #90c9e4;
$ultra-light-blue: #bed6db;
$medium-blue: #2875B0;
$dark-blue: #164469;
$dark-navy-blue: #000a12;
$grey-blue: #6b7897;
$light-black: #232323;

$bluish: rgba(34, 115, 186, 0.1);
$grayish: rgba(199, 199, 204, 0.6);
$greyish: $grayish;
$whisper: #e9e9e9;
$smoke-white: #f6f6f6;
$black-black: #000000;

$greys: ();
// stylelint-disable-next-line scss/dollar-variable-default
$greys: map-merge(
    (
        "100": $grey-100,
        "200": $grey-200,
        "300": $grey-300,
        "400": $grey-400,
        "500": $grey-500,
        "600": $grey-600,
        "700": $grey-700,
        "800": $grey-800,
        "900": $grey-900,
    ),
    $greys
);

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
    (
        "blue": $blue,
        "indigo": $indigo,
        "purple": $purple,
        "pink": $pink,
        "red": $red,
        "orange": $orange,
        "yellow": $yellow,
        "green": $green,
        "teal": $teal,
        "cyan": $cyan,
        "white": $white,
        "grey": $grey-600,
        "grey-dark": $grey-800,
    ),
    $colors
);

// Primary Guided colors 

$primary: $blue;
$secondary: $grey-600;
$success: $green;
$info: $cyan;
$danger: $red;
$light: $grey-100;
$dark: $grey-800;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
    ),
    $theme-colors
);

// //Button Colors
$primary-button-default: $primary;
$primary-button-hover: #164469;
$primary-button-pressed: #003359;

// Fonts

$font-family-base: Roboto-light;
$font-family-regular: Roboto-regular;
$font-family-bold: Roboto-bold;

$font-size-base: 1rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * .875);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: 23px;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$small-font-size: 80%;



